/* AddPathologyForm.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  width: 700px;
  max-height: 624px;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 16px;
}

.required {
  color: red;
  margin-right: 12px;
}

/* input[type="text"],
select { */
.form-input,
.form-select {
  width: 60%;
  padding: 8px;
  margin-top: 5px;
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.helper {
  color: red;
  font-size: 12px;
  margin-bottom: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.submit-button,
.close-popup {
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.submit-button {
  background-color: #007bff;
  flex: 1;
  max-width: 40%;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #0056b3;
}

.close-popup {
  background-color: #ff4d4d;
  flex: none;
}

.close-popup:hover {
  background-color: #ff1a1a;
}
