body {
  font-family: Arial, sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 20px;
}

.table-container {
  width: 800px;
  max-width: 800px;
  margin: 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  float: left;
}

.table-title {
  text-align: left;
  color: #333;
}

.pathologies-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pathologies-table th,
.pathologies-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.pathologies-table th {
  background-color: #007bff;
  color: white;
}

.pathologies-table tr:hover {
  background-color: #f1f1f1;
}

.pathologies-table tbody tr:hover{
  cursor: pointer;
}

.loading,
.error {
  text-align: left;
  font-size: 1.2rem;
  margin-top: 20px;
  color: #555;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.add-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.ajouter-une-pathologie-text {
  font-size: 0.9rem;
  margin-right: 8px;
}