.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
  width: 700px;
  max-height: 624px;
  overflow-y: auto; /* Add scroll if content exceeds max height */
}

.pathology-details {
  margin-bottom: 20px;
}

.pathology-details p {
  margin: 8px 0;
  font-size: 14px;
  line-height: 1.6;
}

.pathology-details label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.pathology-details input {
  width: 50%;
  padding: 8px;
  margin-left: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.close-popup,
.save-popup {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.close-popup {
  background-color: #f44336;
  color: white;
}

.close-popup:hover {
  background-color: #d32f2f;
}

.save-popup {
  background-color: #4caf50;
  color: white;
}

.save-popup:hover {
  background-color: #388e3c;
}
